<template>
  <div>
    <mt-spinner type="snake"></mt-spinner>
  </div>
</template>
<script>
import { axios } from '@/utils/request'
import store from '@/store'
// import { Toast } from 'mint-ui'
export default {
  // 生命周期函数
  created () {
    const code = this.GetUrlParame('code') // 截取code
    console.log(code)
    // alert('kong' + code)
    if (!code) {
      var domine = window.location.href.split('#')[0] // 微信会自动识别#    并且清除#后面的内容
      // 这里的axios是已封装过的
      axios
        .get('/api-user/mp/bind/wx32d13c30a5608192/get-redirect-url?domain=' + domine) // 如果没有code，说明用户还没授权   将当前地址传递给后台
        .then(res => {
          console.log(res)
          alert(res.data)
          // Toast('获取微信认证链接')
          window.location.href = res.data
        })
    } else {
      // Toast('开始进行微信认证')
      // alert(code)
      axios
        .get('/api-user/mp/bind/get-openid?code=' + code) // 如果有code，说明用户点击了授权  将获取到的code传递给后台
        .then(res => {
          // 返回状态和UId
          console.log(res.data)
          // 获取到openId
          this.openId = res.data
          // Toast('保存微信认证信息')
          store.commit('SET_WECHAT_OPEN_ID', res.data)
          // Toast('认证完成开始跳转')
          this.$router.replace({
            name: 'login',
            query: { openId: res.data }
          })
        })
    }
  },
  data () {
    return {
      openId: ''
    }
  },
  methods: {
    // 截取code
    GetUrlParame (parameName) {
      /// 获取地址栏指定参数的值
      /// <param name="parameName">参数名</param>
      // 获取url中跟在问号后面的部分
      var parames = window.location.search
      // 检测参数是否存在
      if (parames.indexOf(parameName) > -1) {
        var parameValue = ''
        parameValue = parames.substring(parames.indexOf(parameName), parames.length)
        // 检测后面是否还有参数
        if (parameValue.indexOf('&') > -1) {
          // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
          parameValue = parameValue.substring(0, parameValue.indexOf('&'))
          // 去掉参数名, 得到最终纯值字符串
          parameValue = parameValue.replace(parameName + '=', '')
          return parameValue
        }
        return ''
      }
    }
  }
}
</script>
